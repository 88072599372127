import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { List } from "../components/List";
import { getPath, Item } from "../data/data";

interface PageParams {
  key: string;
}
const Page: React.FC<RouteComponentProps<PageParams>> = ({
  match,
  location
}) => {
  const [lastPath, setLastPath] = useState<Item>();
  const { key } = match.params;

  useEffect(() => {
    const items = key.split("/");
    const path = getPath(items);

    setLastPath(path[path.length - 1]);
  }, [key, location.pathname]);

  if (!lastPath) {
    return <></>;
  }

  const renderPath = lastPath.subRoutes ? (
    <List menu={lastPath.subRoutes} path={location.pathname} />
  ) : (
    <lastPath.component />
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{lastPath.label}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{renderPath}</IonContent>
    </IonPage>
  );
};

export default Page;
