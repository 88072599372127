import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { Menu, Item } from "../data/data";

interface ListProps {
  menu: Menu;
  path: string;
}
export const List: React.FC<ListProps> = ({ menu, path }) => {
  const renderItems = menu
    .sort((a: Item, b: Item) => {
      return a.label.localeCompare(b.label);
    })
    .map(k => (
      <IonItem routerLink={`${path}/${k.route}`} key={k.route}>
        <IonLabel>{k.label}</IonLabel>
      </IonItem>
    ));

  return <IonList>{renderItems}</IonList>;
};
