import {
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSearchbar
} from "@ionic/react";
import React, { useState } from "react";

export const Telefoonnummers: React.FC = () => {
  const [value, setValue] = useState("");
  const contacts = [...stafleden, ...arts];

  const onChange = (e: any) => {
    const targetValue = e.target.value;
    setValue(targetValue);
  };

  const renderItems = contacts
    .filter(c => {
      if (!value) {
        return true;
      }

      if (!c.name) {
        return false;
      }

      return c.name.toLowerCase().includes(value.toLowerCase());
    })
    .map(c => (
      <IonItem>
        <IonLabel>
          {c.name}
          {c.kamer && <p>Kamer: {c.kamer}</p>}
        </IonLabel>
        <IonNote color="primary" slot="end">
          {c.intern}
        </IonNote>
      </IonItem>
    ));

  return (
    <>
      <IonSearchbar value={value} onIonChange={onChange}></IonSearchbar>
      <IonList lines="full">{renderItems}</IonList>
    </>
  );
};

interface Contact {
  name: string;
  intern: string;
  kamer?: string;
}

const stafleden: Contact[] = [
  { name: "Crama", intern: "87055" },
  { name: "Eggink", intern: "87050", kamer: "15216" },
  { name: "Hoyng", intern: "87075", kamer: "17301" },
  { name: "Huet, van", intern: "96545", kamer: "14447" },
  { name: "Keijser", intern: "87052" },
  { name: "Klaver", intern: "93432", kamer: "14596" },
  { name: "Klevering", intern: "87049", kamer: "16341" },
  { name: "Nobacht", intern: "87155" },
  { name: "Phan", intern: "*81 3468" },
  { name: "Theelen", intern: "87054", kamer: "14447" },
  { name: "Tilanus", intern: "87048" },
  { name: "Vries, de", intern: "87271" }
];

const arts: Contact[] = [
  { name: "Asten, van", intern: "1312" },
  { name: "Bax", intern: "2072" },
  { name: "Breukink", intern: "2731" },
  { name: "Declerck", intern: "3602" },
  { name: "El Kandoussi", intern: "2728" },
  { name: "Kersten", intern: "2727" },
  { name: "Lambertus", intern: "3405" },
  { name: "Lamers", intern: "2855" },
  { name: "Lanting", intern: "1741" },
  { name: "Lechanteur", intern: "1743" },
  { name: "Schreur", intern: "2730" },
  { name: "Teunissen", intern: "2913" },
  { name: "Valkenburg", intern: "1742" },
  { name: "Verbakel", intern: "2725" }
];
