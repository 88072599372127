import { Laser, Endoftalmitis } from "./protocollen";
import { Telefoonnummers } from "./Telefoonnummers";

export interface Item {
  route: string;
  label: string;
  subRoutes?: Menu;
  component?: any;
}

export type Menu = Item[];

const ProtocollenMenu: Menu = [
  {
    route: "endoftalmitis",
    label: "Endoftalmitis",
    component: Endoftalmitis
  },
  {
    route: "controletermijn",
    label: "Controletermijn"
  },
  {
    route: "cornea-ulcus",
    label: "Cornea ulcus"
  },
  {
    route: "laser",
    label: "Laser",
    component: Laser
  },
  {
    route: "pre-operatief-beleid",
    label: "Pre-operatief beleid"
  },
  {
    route: "post-operatief-beleid",
    label: "Post-operatief beleid"
  },
  {
    route: "acuut-glaucoom",
    label: "Acuut glaucoom"
  }
];

const ZakkaartjesMenu: Menu = [
  {
    route: "-patient",
    label: "Bedreigde patient"
  },
  {
    route: "screening-ernstige-sepis",
    label: "Screening ernstige sepis"
  },
  {
    route: "pijnscore-en-delirium-checklist",
    label: "Pijnscore en delirium checklist"
  },
  {
    route: "rsvp",
    label: "Rsvp"
  }
];

export const HomeMenu: Menu = [
  {
    route: "protocollen",
    label: "Protocollen",
    subRoutes: ProtocollenMenu
  },
  {
    route: "zakkaartjes",
    label: "Zakkaartjes",
    subRoutes: ZakkaartjesMenu
  },
  {
    route: "telefoonnummers",
    label: "Telefoonnummers",
    component: Telefoonnummers
  },
  {
    route: "agenda",
    label: "Agenda",
    subRoutes: []
  },
  {
    route: "opleidingsplan",
    label: "Opleidingsplan",
    subRoutes: []
  },
  {
    route: "functieonderzoeken",
    label: "Functieonderzoeken",
    subRoutes: []
  }
];

export const getPath = (items: string[]) => {
  const path: Item[] = [];

  items.forEach(route => {
    let menu: Item[] = HomeMenu;

    if (path.length) {
      const subRoutes = path[path.length - 1].subRoutes;

      if (subRoutes) {
        menu = subRoutes;
      }
    }

    const subMenu = menu.find(m => m.route === route);

    if (subMenu) {
      path.push(subMenu);
    }
  });

  return path;
};
