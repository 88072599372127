import React from "react";

export const Endoftalmitis: React.FC = () => {
  return (
    <div className="content">
      <strong>Doel</strong>
      <p>
        Standaardisering en uniformiteit van de behandeling en medische zorg aan
        de patiënt met een acute postoperatieve endophthalmitis.
      </p>
      <strong>Definities</strong>
      <p>
        Acute postoperatieve endophthalmitis is een (meestal bacteriële)
        ontsteking van het voor- en achtersegment van het oog binnen 6 weken na
        een oogoperatie.
      </p>
      <strong>Indicatie</strong>
      <p>
        Dit protocol wordt toegepast bij patiënten met een acute postoperatieve
        endophthalmitis.
      </p>

      <strong>Werkwijze/beslisboom</strong>
      <strong>Aannemen patiënt</strong>
      <ul>
        <li>
          Spoedarts krijgt via doorverwijzing van extern oogarts de
          patiëntgegevens door en laat patiënt direct naar radboudumc komen.
        </li>
        <li>
          Spoedarts  bestelt DIRECT intravitreale medicatie bij apotheek (indien
          patiënt al bekend is/aangemeld is in radboud umc) anders wachten
          totdat patiënt in huis is en dan direct medicatie bestellen.
        </li>
        <li>Dit kan via de smartset: endophthalmitis</li>
        <ul>
          <li>R/ Vancomycine 10 mg/ml</li>
          <ul>
            <li>da 0.3 ml</li>

            <li>S/ op OK oogheelkunde</li>
            <li>voor intravitreaal gebruik.</li>
          </ul>
          <li>R/ Ceftazidine 22,5 mg/ml</li>
          <ul>
            <li>da 0.3 ml</li>
            <li>S/ op OK oogheelkunde</li>
            <li>voor intravitreaal gebruik.</li>
          </ul>
          <li>!! SPOED !!</li>
        </ul>

        <li>
          Er is een regeling met 24 uurs apotheek (poli apotheek) dat zij altijd
          een noodset gereed hebben liggen aldaar. Indien dit niet het geval is
          kost het namelijk minstens 2 uur bereidingstijd bij hen. Dus van
          tevoren naar hen bellen om te verifiëren of er een noodset klaarligt,
          kan altijd al voordat een patiënt in huis is.
        </li>
        <li>
          Als je via de smartset recepten print kan je in de dienst zelf naar
          apotheek, of binnen normale werktijd de antibiotica door een
          verpleegkundige op laten halen.
        </li>

        <li>
          (In geval van onvoorziene afwezigheid van medicatie op OK kan
          medicatie ook bereid worden op OK gemaakt worden (zie handleiding op
          OK53--> link naar OK.)
        </li>
      </ul>
      <strong>Diagnose</strong>
      <ul>
        <li>Spoedarts</li>
        <ul>
          <li>Voert oogheelkundig onderzoek uit. </li>
          <li>Vraagt supervisor akkoord diagnose  en behandeling</li>
          <li>Ordert OK in EPIC via smartset enodphthalmitis</li>
        </ul>
      </ul>

      <strong>Behandelen</strong>
      <ul>
        <li>Operateur (in de dienst: spoedarts)</li>
        <ul>
          <li>Afname glasvochtbiopt op OK.</li>
          <ul>
            <li>
              0.3 cc glasvocht afnemen met vitrectoom (25 of 27 gage) (opzuigen
              tot slangetje vol is, dan ongeveer 0,3 cc)
            </li>
            <li>Intravitreale injectie van 0.1cc Vancomycine 10 mg/ml </li>
            <li>
              Tevens intravitreale injectie van 0.1 cc Ceftazidine 22,5 mg/ml
            </li>
            <li>Beiden via trocar opening van afname glasvocht biopt</li>
          </ul>

          <li>
            Injectie en dosering van medicatie op OK- oogheelkunde volgens
            werkwijze klapper op OK 53.
          </li>
          <li>
            Na injectie een Tobradex zalfverband geven en oog met eyepad en
            kapje afdekken
          </li>
          <li>PA aanvraag maken in epic: oogvocht</li>
          <li>
            Biopt in dienst of na 17:00 zelf naar lab brengen, binnen werkuren
            kan lab het meestal komen halen, anders via zaalarts het laten
            brengen naar lab: route 834
          </li>
        </ul>
      </ul>

      <strong>Vervolg behandeling</strong>
      <ul>
        <li>Tobradex oogdruppels 3dd gedurende 1 week </li>
        <li>
          Controle 2 dagen na 1e gift antibioticum voor eventueel 2e gift
          plannen (order staat ook in dezelfde smartset endophtalmitis){" "}
        </li>
        <li>
          Ook aanvraag voor 2e gift vanco en cefta voor dag 3 wordt via smartset
          endophtalmitis georderd. Zorg wel dat verpleegkundige op de hoogte
          hiervan is zodat zij kunnen zorgen dat de antibioticum ook
          daadwerkelijk bij (ziekenhuis) apotheek besteld wordt en op tijd
          afgeleverd wordt. Check dit ook altijd even.{" "}
        </li>
        <li>
          Op dag 3 afhankelijk van klinisch beeld en uitkomst van kweek wel of
          geen 2e gift antibioticum geven. Afhankelijk van kweek uitslag is soms
          maar 1 van beide antibioticum nodig.{" "}
        </li>
      </ul>

      <strong>Controles</strong>
      <ul>
        <li>
          Direct kweek-preparaat wordt meestal binnen 24 uur doorgebeld door de
          microbioloog. Enkele dagen later kan de kweek + resistentie bekend
          zijn.
        </li>
        <li>Kijk hiervoor in EPIC onder MMB; bel zo nodig dienstdoende MMB </li>
        <li>Oogheelkundig onderzoek na 2 dagen (= dag 3)</li>
      </ul>
      <strong>Alarmeringen</strong>
      <p>
        Verdere visusdaling; toename van pijn; toename van algehele malaise;
        toename van roodheid; koorts
      </p>
    </div>
  );
};
