import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React from "react";
import { List } from "../components/List";
import { HomeMenu } from "../data/data";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>👁 Care</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <List menu={HomeMenu} path="" />
      </IonContent>
    </IonPage>
  );
};

export default Home;
